<template>
    <!-- 短期评估 -->
    <div class="shortAss">
        <el-table :data="tableData"  border style="width: 100%; " :header-cell-style="{
            background: '#EEEEEE', color: '#303030', fontWeight: '500'
        }">
            <el-table-column prop="name" label="量表名称" >
            </el-table-column>
            <el-table-column prop="amount" label="内容介绍">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [{
                id: 1,
                time: 'D0',
                name: '90Scl-90',
                amount: ''
            }, {
                id: 2,
                time: 'D1-3',
                name: '9项患者健康问卷(PHQ-9)',
                amount: '了解测评者近两周内是否存在抑郁状态，是基于美国精神疾病诊断与统计手册制定的抑郁自评工具。'
            }, {
                id: 3,
                time: 'D4',
                name: '广泛性焦虑自评量表（GAD-7）',
                amount: '用于评估焦虑情绪的量表，定期（1次/1-2周）自评可以观察焦虑情绪变化趋势和治疗效果。'
            }, {
                id: 4,
                time: 'D5',
                name: '睡眠状况自评量表',
                amount: '了解测评者近一个月内的睡眠状况，适用于筛选有睡眠问题的不同人群，也可用于睡眠问题者治疗前后测量，了解失眠者治疗的效果。'
            }, {
                id: 5,
                time: 'D5',
                name: '成人心理压力量表',
                amount: '用于了解当前自身的压力情况，考查近期经受的心理压力大小。'
            }, {
                id: 6,
                time: 'D6-7',
                name: '职业倦怠量表修订版',
                amount: '用于评估工作倦怠情况，包含三个纬度：情绪衰竭、去人性化和个人成就感。'
            }, {
                id: 7,
                time: 'D6-7',
                name: '社会支持评定量表',
                amount: '包括客观支持、主观支持和对社会支持的利用度三个维度'
            }, {
                id: 8,
                time: '',
                name: 'MBTI职业性格测试量表',
                amount: '广泛应用于：1.自我了解和发展；2.职业发展和指导；3.组织发展；4.团队组建；5.管理和领导培训；6.问题解决；7.人际关系咨询；8.教育及课程发展等。'
            },{
                id: 9,
                time: '',
                name: '成年人心理弹性量表',
                amount: '包括支持性、乐观性、内控性、应对性和接纳性。得分越髙，心理弹性越好。'
            }
        ]
        }
    }
}
</script>

<style>

</style>